<template>
  <div class="d-flex my-1">
    <div class="d-flex flex-column align-items-start mr-3" :style="{ maxWidth: '40ch', minWidth: '25ch' }">
      <strong class="text-nowrap">
        <i class="pr-2" :class="methodIconsMap[method.name]"></i>
        {{ method.name }}</strong>
      <small> {{ method.description }}</small>
    </div>
    <div v-if="method.name !== 'AtlasPosting'">
      <span class="switch switch-icon">
        <label>
          <input type="checkbox" :checked="isActive" :disabled="isDisabled" :tooltip="tooltipText"
            @change="handleClick()" />
          <span></span>
        </label>
      </span>
    </div>
    <div v-else>
      <span class="switch switch-icon">
        <label>
          <input type="checkbox" checked="checked" disabled="disabled" :tooltip="tooltipText" />
          <span></span>
        </label>
      </span>
    </div>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
export default {
  props: {
    method: {
      type: Object,
      required: true,
    },

    notificationType: {
      type: Number,
      required: true,
    },

    notifications: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      icons,
      methodIconsMap: {
        AtlasPosting: icons.notify,
        Email: icons.email,
        // SMS: icons.phone,
      },
    };
  },

  computed: {
    isActive() {
      return this.notifications.find(
        (el) =>
          el.notification_type?.id === this.notificationType && el.delivery_method.find((m) => m.id === this.method.id),
      );
    },

    isDisabled() {
      if (!this.notificationObject) return true;
      return !!(
        !this.notificationObject.delivery_method.length ||
        (this.notificationObject.delivery_method.length < 2 && this.isActive)
      );
    },

    notificationObject() {
      return this.notifications.find((el) => el.notification_type.id === this.notificationType);
    },

    tooltipText() {
      if (!this.notificationObject) return this.$t("Enable notification type first");
      if (this.notificationObject.delivery_method.length < 2 && this.isActive)
        return this.$t("You must have a delivery method for this type");
      if (this.isActive) return this.$t("Disable method");
      return this.$t("Enable method");
    },
  },

  methods: {
    handleClick() {
      if (this.isActive) {
        this.$emit("remove", { notificationType: this.notificationType, deliveryMethod: this.method });
        return;
      }
      this.$emit("add", { notificationType: this.notificationType, deliveryMethod: this.method });
    },
  },
};
</script>
