var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-start mr-3",
    style: {
      maxWidth: '30ch',
      minWidth: '25ch'
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.item.name))]), _c('small', [_vm._v(" " + _vm._s(_vm.item.description))])]), _c('span', {
    staticClass: "switch switch-icon"
  }, [_c('label', [_c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.isActive
    },
    on: {
      "change": function change($event) {
        return _vm.handleClick(_vm.item);
      }
    }
  }), _c('span')])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }