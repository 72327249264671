import api from "@/core/services/api.service";

export default {
  get PATH() {
    return "invitations";
  },

  async createInvitation({
    email,
    physicalPerson,
    userID,
    groups_data = [],
    company = null,
    inviteType,
    role = null,
    paycheckPermission = false,
    certificateSuitability = false,
    identityDocumentPermission = false,
    contractPermission = false,
    postingCertificatePermission = false,
    title = null
  }) {
    const body = {
      invite_type: inviteType,
      email,
      physicalperson_data: physicalPerson,
    }
    if (userID) {
      body.user_id = userID;
    }
    if (company) {
      body.company_data = company;
    }
    if (groups_data) {
      body.groups_data = groups_data;
    }
    if (role) {
      body.role = role;
    }
    if (title) {
      body.title = title;
    }
    if (paycheckPermission) {
      body.paycheck_permission = paycheckPermission
    }
    if (certificateSuitability) {
      body.certificate_suitability_for_work_permission = certificateSuitability
    }
    if (identityDocumentPermission) {
      body.identity_document_permission = identityDocumentPermission
    }
    if (contractPermission) {
      body.contract_permission = contractPermission
    }
    if (postingCertificatePermission) {
      body.posting_certificate_permission = postingCertificatePermission
    }
    const r = await api.post(`${this.PATH}/`, body);
    return r.data;
  },
  async resendInvitation(email) {
    const r = await api.post(`${this.PATH}/resend_invitation_email/`, {
      email,
    });

    return r.data;
  },
};