<template>
  <b-modal id="notifications-settings-modal" size="xl" :title="$t('Notifications settings')">

    <Datatable ref="settingsTable" :table-props="tableProps" :paginated="false">
      <template #[`cell.type`]="{ item }">
        <NotificationType :item="item" :active-notifications="localSettings" @add="handleNotificationAdd"
          @remove="handleNotificationRemove"></NotificationType>
      </template>

      <template #[`cell.delivery_methods`]="{ item: { id }, item: { delivery_methods: methods } }">
        <div v-for="(method, idx) in methods" :key="idx" class="d-flex justify-content-start align-items-center">
          <DeliveryMethodCell :notification-type="id" :method="method" :notifications="localSettings"
            @add="handleMethodAdd" @remove="handleMethodRemove"></DeliveryMethodCell>
        </div>
      </template>
    </Datatable>

    <template #modal-footer>
      <b-button variant="success" @click="save">
        {{ $t('Save Changes') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import icons from "@/core/config/icons";

import NotificationSettingsService from "@/core/services/notificationsettings/notificationsettings.service";
import Datatable from "@/view/components/tables/Datatable.vue";

import NotificationType from "@/view/components/tables/notifications-settings-table/NotificationType.vue";
import DeliveryMethodCell from "@/view/components/tables/notifications-settings-table/DeliveryMethodCell.vue";

import { backendErrorSwal } from "@/core/helpers/swal";
import usersService from "@/core/services/users.service";
import CompanyService from "@/core/services/company/company.service";
import { successToast } from '@/core/helpers';

const DEFAULT_NOTIFICATION_ITEM = {
  notification_type: null,
  delivery_method: [],
};

export default {
  components: {
    Datatable,
    NotificationType,
    DeliveryMethodCell,
  },

  props: {
    entity: {
      type: [Number, null],
      default: null,
    },

    userName: {
      type: String,
      default: "",
    },

    notifications: {
      type: Array,
      default: () => [],
    },

    entityType: {
      type: String,
      default: "user",
    },
  },

  data() {
    return {
      icons,
      notificationItem: DEFAULT_NOTIFICATION_ITEM,
      notificationTypes: [],
      deliveryMethods: [],
      localSettings: [],
      notificationItems: [],
      entityMap: {
        user: usersService.editUser,
        focus: CompanyService.update,
      },
    };
  },

  computed: {
    typeSelectProps() {
      return {
        options: this.notificationTypes.filter(
          (el) => !this.localSettings.find((item) => item.notification_type.id === el.id),
        ),
        label: "name",
        "track-by": "id",
        multiple: false,
        "allow-empty": true,
        searchable: false,
        "show-labels": false,
      };
    },

    methodSelectProps() {
      return {
        options: this.deliveryMethods,
        label: "name",
        "track-by": "id",
        "allow-empty": true,
        searchable: false,
        multiple: true,
        "show-labels": false,
      };
    },

    tableProps() {
      return {
        items: this.notificationItems,
        fields: [
          { key: "type" },
          { key: "delivery_methods" }
        ],
      };
    },
  },

  watch: {
    entity() {
      this.localSettings = [...this.notifications];
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    handleMethodRemove({ notificationType, deliveryMethod }) {
      const notification = this.localSettings.find((n) => n.notification_type.id === notificationType);
      if (notification) {
        notification.delivery_method.splice(
          (notification.delivery_method = notification.delivery_method.filter((x) => x.id !== deliveryMethod.id)),
          1,
        );
      }
    },

    handleMethodAdd({ notificationType, deliveryMethod }) {
      const notification = this.localSettings.find((n) => n.notification_type.id === notificationType);
      if (notification) {
        notification.delivery_method.push(deliveryMethod);
      }
    },
    handleNotificationRemove(id) {
      this.localSettings = this.localSettings.filter((s) => s.notification_type.id !== id);
    },

    handleNotificationAdd(item) {
      const defaultMethod = this.deliveryMethods?.[0];
      this.localSettings.push({
        notification_type: {
          id: item.id,
          name: item.name,
          description: item.description,
        },
        delivery_method: defaultMethod ? [defaultMethod] : [],
      });
    },

    removeNotificationType(id) {
      this.localSettings = this.localSettings.filter((s) => s.notification_type.id !== id);
    },

    removeDeliveryMethod(item, methodId) {
      this.localSettings = this.localSettings.map((li) => {
        if (li.notification_type !== item.notification_type) return li;
        return {
          ...li,
          delivery_method: li.delivery_method.filter((dm) => dm.id !== methodId),
        };
      });
    },

    addNotificationType() {
      const [item] = [this.notificationItem].map((el) => {
        return {
          notification_type: {
            id: el.notification_type,
            name: this.notificationTypes.find((t) => t.id === el.notification_type)?.name,
            description: this.notificationTypes.find((t) => t.id === el.notification_type)?.description,
          },
          delivery_method: el.delivery_method.map((m) => {
            return {
              id: m,
              name: this.deliveryMethods.find((dm) => dm.id === m)?.name,
              description: this.deliveryMethods.find((dm) => dm.id === m)?.description,
            };
          }),
        };
      });
      this.localSettings.push(item);
      this.$nextTick(() => {
        this.notificationItem.delivery_method.length = 0;
        this.notificationItem.notification_type = null;
      });
    },

    async save() {
      try {
        await this.entityMap[this.entityType](this.entity, { notificationsettings_set: this.localSettings });
        await successToast();
        this.$emit("close-refresh");
      } catch (err) {
        backendErrorSwal(err?.response?.detail, err);
      }
    },

    async init() {
      try {
        this.localSettings = [...this.notifications];
        const options = await NotificationSettingsService.getOptions();
        const { notification_types, delivery_methods } = options;

        this.notificationTypes = notification_types || [];


        this.deliveryMethods = delivery_methods.filter(el => el.name != "SMS") || [];
        //TODO: se il name è SMS skippa
        // filter(el => el.name != 'SMS')


        this.notificationItems = [];
        this.notificationTypes.forEach((t) => {
          this.notificationItems.push({
            ...t,
            delivery_methods: delivery_methods.filter(el => el.name != "SMS"),
          });
        });
      } catch (err) {
        backendErrorSwal(err?.response?.detail, err);
      }
    },
  },
};
</script>
