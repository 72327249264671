import api from "@/core/services/api.service";

export default {
  get PATH() {
    return "notificationsettings";
  },

  async getAll({ perPage, page, sortBy = null, sortDesc = null, search = null, user = null }) {
    const offset = (page - 1) * perPage;
    const params = { limit: perPage, offset, search, user };
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });

    return res.data;
  },

  async getOptions() {
    const res = await api.get(`${this.PATH}/get_notifications_options/`);

    return res.data;
  },
};
