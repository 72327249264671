var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex my-1"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-start mr-3",
    style: {
      maxWidth: '40ch',
      minWidth: '25ch'
    }
  }, [_c('strong', {
    staticClass: "text-nowrap"
  }, [_c('i', {
    staticClass: "pr-2",
    class: _vm.methodIconsMap[_vm.method.name]
  }), _vm._v(" " + _vm._s(_vm.method.name))]), _c('small', [_vm._v(" " + _vm._s(_vm.method.description))])]), _vm.method.name !== 'AtlasPosting' ? _c('div', [_c('span', {
    staticClass: "switch switch-icon"
  }, [_c('label', [_c('input', {
    attrs: {
      "type": "checkbox",
      "disabled": _vm.isDisabled,
      "tooltip": _vm.tooltipText
    },
    domProps: {
      "checked": _vm.isActive
    },
    on: {
      "change": function change($event) {
        return _vm.handleClick();
      }
    }
  }), _c('span')])])]) : _c('div', [_c('span', {
    staticClass: "switch switch-icon"
  }, [_c('label', [_c('input', {
    attrs: {
      "type": "checkbox",
      "checked": "checked",
      "disabled": "disabled",
      "tooltip": _vm.tooltipText
    }
  }), _c('span')])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }