var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "notifications-settings-modal",
      "size": "xl",
      "title": _vm.$t('Notifications settings')
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.save
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('Save Changes')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('Datatable', {
    ref: "settingsTable",
    attrs: {
      "table-props": _vm.tableProps,
      "paginated": false
    },
    scopedSlots: _vm._u([{
      key: "cell.type",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('NotificationType', {
          attrs: {
            "item": item,
            "active-notifications": _vm.localSettings
          },
          on: {
            "add": _vm.handleNotificationAdd,
            "remove": _vm.handleNotificationRemove
          }
        })];
      }
    }, {
      key: "cell.delivery_methods",
      fn: function fn(_ref2) {
        var id = _ref2.item.id,
          methods = _ref2.item.delivery_methods;
        return _vm._l(methods, function (method, idx) {
          return _c('div', {
            key: idx,
            staticClass: "d-flex justify-content-start align-items-center"
          }, [_c('DeliveryMethodCell', {
            attrs: {
              "notification-type": id,
              "method": method,
              "notifications": _vm.localSettings
            },
            on: {
              "add": _vm.handleMethodAdd,
              "remove": _vm.handleMethodRemove
            }
          })], 1);
        });
      }
    }], null, true)
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }