<template>
  <div class="d-flex justify-content-start align-items-center">
    <div class="d-flex flex-column align-items-start mr-3" :style="{ maxWidth: '30ch', minWidth: '25ch' }">
      <strong>{{ item.name }}</strong>
      <small> {{ item.description }}</small>
    </div>
    <span class="switch switch-icon">
      <label>
        <input type="checkbox" :checked="isActive" @change="handleClick(item)" />
        <span></span>
      </label>
    </span>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    activeNotifications: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      icons,
      isActive: false,
    };
  },

  computed: {
    tooltipText() {
      return this.isActive
        ? this.$t("Disable notification type")
        : this.$t("Enable notification type");
    },
  },

  watch: {
    activeNotifications: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.isActive = val.find((n) => n?.notification_type?.id === this.item.id);
        }
      },
    },
  },

  methods: {
    handleClick(item) {
      if (this.isActive) {
        this.$emit("remove", item.id);
        return;
      }
      this.$emit("add", item);
    },
  },
};
</script>
